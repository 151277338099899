import axios from 'axios';
import { API_HOSTNAME_QR_REQUEST, API_HOSTNAME_DOCUMENT_REQUEST } from '../types/apiConstantesConexion';
import  "../components/core.js";

const b64toBlob = (b64Data, contentType='', sliceSize=512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, {type: contentType});
  return blob;
}

export const consultarDocumento = (props) => {
 
      console.log("propssss ",props)
        var name='d';
        name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
        var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
            results = regex.exec(window.location.search);
        var codU= results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
      const documentoCU = {
        CodigoUnico: codU
      };
      document.getElementById("dvPreloader").style.display="inline";

      var datadoc;
    
      let response=  axios.post(`${API_HOSTNAME_QR_REQUEST}`, documentoCU)  .then(res => {
       
        const documento = {
            Location: res.data.ruta,
            Name:res.data.nombre,
            Repository:res.data.repositorio,
            archivoBase64: res.data.archivoBase64


          };
          let config = {
            headers: {
                Location: res.data.ruta,
                Name:res.data.nombre,
                Repository:res.data.repositorio
            }
          }
         
          if(documento.archivoBase64!='')
          {
            const blob = b64toBlob(documento.archivoBase64, "application/pdf");
            props.setFile(blob)
            props.setFileName(documento.Name)      
            document.getElementById("dvControlSucces").style.display="inline";
            document.getElementById("dvPreloader").style.display="none";
            //document.getElementById("ifrmDocument").src="data:application/pdf;base64," + documento.archivoBase64
          }
          else
          {
             
            const responseDoc =  axios.get(`${API_HOSTNAME_DOCUMENT_REQUEST}`,config).then(resDoc => {
                console.log(resDoc);
                const blob = b64toBlob(resDoc.data.archivoBase64, "application/pdf");
                props.setFile(blob)
                console.log(" Nombre:    -   "+ documento.Name + "   "+documento.Repository)
                props.setFileName(documento.Name)  
                document.getElementById("dvControlSucces").style.display="inline";
                document.getElementById("dvPreloader").style.display="none";
    
            }) .catch(error => {
              alert("Documento no fue encontrado")
                   /* this.props.enqueueSnackbar("Documento no fue", {
                      variant: 'error',
                      anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                      },
                    });*/
                });
  
            console.log(responseDoc);
          }
        
      }).catch(error => {
        alert("Documento no fue encontrado")
        console.log(error)
        document.getElementById("dvPreloader").style.display="none";
        /*props.enqueueSnackbar("Documento no fue encontrado", {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });*/
      });
     
     
      return  response;
     
   
  };

  export default consultarDocumento;

